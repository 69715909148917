<template>
  <div class="cadastro-sms">
    <voltar />
    <h1 class="titulo">CADASTRO</h1>
    <p>
      Estamos quase lá! Precisamos que informe o código de 6 dígitos que
      enviamos para seu telefone
      <span class="texto-destaque">{{user.celular}}</span>.
    </p>
    <p>Isso é só para garantir a sua segurança.</p>
    <form action="" class="form" @submit.prevent="handleSubmit">
      <div class="form__sms">
        <input type="text" ref="numero1" maxlength="1" v-model="state.numero1.value" v-on:keyup="focusNext($event)" required />
        <input type="text" ref="numero2" maxlength="1" v-model="state.numero2.value" v-on:keyup="focusNext($event)" required />
        <input type="text" ref="numero3" maxlength="1" v-model="state.numero3.value" v-on:keyup="focusNext($event)" required />
        <input type="text" ref="numero4" maxlength="1" v-model="state.numero4.value" v-on:keyup="focusNext($event)" required />
        <input type="text" ref="numero5" maxlength="1" v-model="state.numero5.value" v-on:keyup="focusNext($event)" required />
        <input type="text" ref="numero6" maxlength="1" v-model="state.numero6.value" v-on:keyup="focusNext($event)" required />
      </div>
      <!-- <a href="">Ops, não recebi o código! :(</a> -->
      <div class="bt">
        <button type="submit" :disabled="state.isLoading">AVANÇAR</button>
      </div>
    </form>
  </div>
</template>
<script>
import { reactive } from 'vue'
// import { useRouter } from 'vue-router'
import { useField } from 'vee-validate'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'

export default {
  setup () {
    // const router = useRouter()
    const toast = useToast()
    const user = JSON.parse(window.localStorage.getItem('user'))

    const { value: numero1Value, errorMessage: numero1ErrorMessage } =
      useField('numero1')

    const { value: numero2Value, errorMessage: numero2ErrorMessage } =
      useField('numero2')

    const { value: numero3Value, errorMessage: numero3ErrorMessage } =
      useField('numero3')

    const { value: numero4Value, errorMessage: numero4ErrorMessage } =
      useField('numero4')

    const { value: numero5Value, errorMessage: numero5ErrorMessage } =
      useField('numero5')

    const { value: numero6Value, errorMessage: numero6ErrorMessage } =
      useField('numero6')

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      numero1: {
        value: numero1Value,
        errorMessage: numero1ErrorMessage
      },
      numero2: {
        value: numero2Value,
        errorMessage: numero2ErrorMessage
      },
      numero3: {
        value: numero3Value,
        errorMessage: numero3ErrorMessage
      },
      numero4: {
        value: numero4Value,
        errorMessage: numero4ErrorMessage
      },
      numero5: {
        value: numero5Value,
        errorMessage: numero5ErrorMessage
      },
      numero6: {
        value: numero6Value,
        errorMessage: numero6ErrorMessage
      }
    })

    async function handleSubmit () {
      try {
        state.isLoading = true
        const { data, errors } = await services.cadastro.sms({
          numero1: state.numero1.value,
          numero2: state.numero2.value,
          numero3: state.numero3.value,
          numero4: state.numero4.value,
          numero5: state.numero5.value,
          numero6: state.numero6.value,
          token: user.token
        })

        if (!errors && !data.error_message) {
          window.localStorage.setItem('user', JSON.stringify(data))
          console.info(data)
          // router.push({ name: 'Assinatura' })
          window.location = '/assinatura'
          state.isLoading = false
          return
        }

        console.log('Erro', errors)
        console.log('Data', data)

        if (data.error_message) {
          toast.error(data.error_message)
        }

        state.isLoading = false
      } catch (error) {
        if (error != null) {
          if (error.status === 404) {
            toast.error('CPF e/ou Senha não encontrado')
          }

          if (error.status === 404 || error.status === 401 || error.status === 400 || error.status === 422) {
            toast.error(error.statusText)
          }
          console.log('error...', error)
          toast.error('Erro ao fazer o Cadastro!')
          state.isLoading = false
          state.hasErrors = !!error
        }
      }
    }

    function focusNext (event) {
      const currentIndex = Array.from(event.target.form.elements).indexOf(event.target)

      if (event.keyCode !== 8) {
        event.target.form.elements.item(currentIndex < event.target.form.elements.length - 1 ? currentIndex + 1 : 0).focus()
      } else {
        event.target.form.elements.item(currentIndex > 0 ? currentIndex - 1 : 0).focus()
      }
    }

    return {
      state,
      focusNext,
      handleSubmit,
      user
    }
  },
  components: { Voltar }
}
</script>
